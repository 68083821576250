import { PRODUCTS_LINKS } from '@/settings/Products';

export default 	{
	color: '#9FC642',
	hero: {
		background: 'hero/background.jpg',
		logo: 'hero/logo.png',
	},
	overview: {
		image: 'overview/uuth-box.png',
		title: 'uuth_title',
		description: 'uuth_overview_description',
		iconCols: 5,
		icons: [
			{
				text: 'uuth_overview_icon_1',
				file: 'overview/icons/youthfull-icon.png',
			},
			{
				text: 'uuth_overview_icon_2',
				file: 'overview/icons/lines-icon.png',
			},
			{
				text: 'uuth_overview_icon_3',
				file: 'overview/icons/heart-icon.png',
			},
			{
				text: 'uuth_overview_icon_4',
				file: 'overview/icons/arm-icon.png',
			},
			{
				text: 'uuth_overview_icon_5',
				file: 'overview/icons/stress-icon.png',
			},
		],
	},
	video: {
		title: 'uuth_video_title',
		url: {
			en: 'https://player.vimeo.com/video/582227359',
			es: 'https://player.vimeo.com/video/659791970',
			ja: 'https://player.vimeo.com/video/582227359',
		},
		previewImg: 'video/preview.png',
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'benefits/benefits-1.jpg',
				title: 'uuth_benefits_title',
				content: 'uuth_benefits_left_content',
			},
			{
				reversed: true,
				image: 'benefits/benefits-2.jpg',
				content: 'uuth_benefits_right_content',
				cta: {
					url: PRODUCTS_LINKS.UT,
					text: 'download_brochure',
					target: '_blank',
				},
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727155489?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/834221606?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727155489?h=e8ad93e197&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/rory-r.png',
				author: 'Rory, R., USA',
				content: 'uuth_review_1',
				stars: 5,
			},
			{
				image: 'reviews/shanna-b.png',
				author: 'Shanna, B., USA',
				content: 'uuth_review_3',
				stars: 5,
			},
			{
				image: 'reviews/holley-s.png',
				author: 'Holley, S., USA',
				content: 'uuth_review_2',
				stars: 5,
			},
			{
				image: 'reviews/dami-p.png',
				author: 'Dami P., USA',
				content: 'uuth_review_4',
				stars: 5,
			},
			{
				image: 'reviews/deana-h.png',
				author: 'Deana H., USA',
				content: 'uuth_review_5',
				stars: 5,
			},
			{
				image: 'reviews/tanya-r.png',
				author: 'Tanya R., USA',
				content: 'uuth_review_6',
				stars: 5,
			},
		],
	},
	buy: {
		background: 'buynow/buy-now.jpg',
		title: 'uuth_title',
		searchName: 'Uuth',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.jpg',
		cta: {
			text: 'contact_title',
		},
	},
};
