import { PRODUCTS_LINKS } from '@/settings/Products';

export default 	{
	colors: {
		primary: '#784c99',
		secondary: '#3f3f3f',
		tertiary: '#fcbe5e',
	},
	introduction: {
		background: 'introduction/hero.png',
		title: 'byom_title',
		description: 'byom_introduction',
		searchName: 'Byom',
		videoUrl: {
			en: 'https://player.vimeo.com/video/800592563?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
			es: 'https://player.vimeo.com/video/801766143?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/812503789?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
		},
		galleryImages: [
			'introduction/byom-box.png',
		],
		variantsImages: ['introduction/strawberry.png'],
	},
	summary: {
		title: 'byom_summary_title',
		description: 'byom_summary_description',
		iconCols: 5,
		image: 'summary/byom-summary.png',
		brochureUrl: PRODUCTS_LINKS.BY,
		icons: [
			{
				text: 'byom_summary_icon_1',
				file: 'summary/icons/ph_balance.svg',
			},
			{
				text: 'byom_summary_icon_2',
				file: 'summary/icons/digestive_health.svg',
			},
			{
				text: 'byom_summary_icon_3',
				file: 'summary/icons/cellular_hydration.svg',
			},
			{
				text: 'byom_summary_icon_4',
				file: 'summary/icons/immune_system.svg',
			},
			{
				text: 'byom_summary_icon_5',
				file: 'summary/icons/body_fight_bacteria.svg',
			},
		],
	},
	overview: [
		{ title: 'byom_overview', content: 'byom_overview_content', image: 'overview/byom-girl.png' },
		{ title: 'byom_probiotics_power', content: 'byom_probiotics_power_content' },
		{ title: 'byom_cfu', content: 'byom_cfu_content' },
	],
	tabs: {
		benefits: {
			content: 'byom_tabs_benefits_content',
			image: 'tabs/byom-snap.png',
		},
		supplementsFacts: {
			images: {
				supplementsFacts: 'tabs/byom-supplement-facts.svg',
			},
			content: 'byom_tabs_supplements_facts_content',
		},
		faqs: [
			{ q: 'byom_tabs_faqs_probiotics_body_ask', a: 'byom_tabs_faqs_probiotics_body_answer' },
			{ q: 'byom_tabs_faqs_probiotics_ask', a: 'byom_tabs_faqs_probiotics_answer' },
			{ q: 'byom_tabs_faqs_pregnant_ask', a: 'byom_tabs_faqs_pregnant_answer' },
			{ q: 'byom_tabs_faqs_combine_ask', a: 'byom_tabs_faqs_combine_answer' },
			{ q: 'byom_tabs_faqs_children_ask', a: 'byom_tabs_faqs_children_answer' },
			{ q: 'byom_tabs_faqs_long_term_ask', a: 'byom_tabs_faqs_long_term_answer' },
			{ q: 'byom_tabs_faqs_flavor_ask', a: 'byom_tabs_faqs_flavor_answer' },
		],
		disclaimer: 'byom_tabs_disclaimer',
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/840622464?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/natasha-r.png',
				author: 'Natasha R., USA',
				content: 'byom_review_1',
				stars: 5,
			},
			{
				image: 'reviews/testimonial_thressa.png',
				author: 'Thressa S., USA',
				content: 'byom_review_2',
				stars: 5,
			},
		],
		disclaimer: 'byom_review_disclaimer',
		disclaimer2: 'byom_review_disclaimer_2',
	},
	buy: {
		background: '#784c99',
		title: 'byom_title_2',
		subtitle: 'byom_subtitle',
		searchName: 'Byom',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.png',
		cta: { text: 'contact_title' },
	},
};
