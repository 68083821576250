import { PRODUCTS_LINKS } from '@/settings/Products';

export default 	{
	color: '#DF703D',
	hero: {
		background: 'hero/background.jpg',
		logo: 'hero/logo.png',
		slogan: 'bran_slogan',
	},
	overview: {
		image: 'overview/bran-box.png',
		title: 'bran_title',
		description: 'bran_overview_description',
		iconCols: 3,
		icons: [
			{
				text: 'bran_overview_icon_1',
				file: 'overview/icons/brain-icon.png',
			},
			{
				text: 'bran_overview_icon_2',
				file: 'overview/icons/arm-icon.png',
			},
			{
				text: 'bran_overview_icon_3',
				file: 'overview/icons/eye-icon.png',
			},
			{
				text: 'bran_overview_icon_4',
				file: 'overview/icons/mood-icon.png',
			},
			{
				text: 'bran_overview_icon_5',
				file: 'overview/icons/idea-icon.png',
			},
			{
				text: 'bran_overview_icon_6',
				file: 'overview/icons/yoga-icon.png',
			},
		],
	},
	video: {
		title: 'bran_video_title',
		url: {
			en: 'https://player.vimeo.com/video/461983332',
			es: 'https://player.vimeo.com/video/472618597',
			ja: 'https://player.vimeo.com/video/577316793',
		},
		previewImg: 'video/preview.png',
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'benefits/benefits-1.jpg',
				title: 'bran_benefits_title',
				content: 'bran_benefits_left_content',
			},
			{
				reversed: true,
				image: 'benefits/benefits-2.jpg',
				content: 'bran_benefits_right_content',
				cta: {
					url: PRODUCTS_LINKS.BR,
					text: 'download_brochure',
					target: '_blank',
				},
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727111655?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/921141761?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727111655?h=de12fdb718&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/heather-b.png',
				author: 'Heather B., USA',
				content: 'bran_review_1',
				stars: 5,
			},
			{
				image: 'reviews/cheryl-w.png',
				author: 'Cheryl W., USA',
				content: 'bran_review_2',
				stars: 5,
			},
			{
				image: 'reviews/tracy-p.png',
				author: 'Tracy P., USA',
				content: 'bran_review_3',
				stars: 5,
			},
			{
				image: 'reviews/sebastian-l.png',
				author: 'Sebastián L., Colombia',
				content: 'bran_review_4',
				stars: 5,
			},
			{
				image: 'reviews/nell-g.png',
				author: 'Nell G., USA',
				content: 'bran_review_5',
				stars: 5,
			},
			{
				image: 'reviews/sam-y.png',
				author: 'Sam Y., USA',
				content: 'bran_review_6',
				stars: 5,
			},
		],
	},
	buy: {
		background: 'buynow/buy-now.jpg',
		title: 'bran_title',
		searchName: 'Bran',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.jpg',
		cta: {
			text: 'contact_title',
		},
	},
};
