import { PRODUCTS_LINKS } from '@/settings/Products';

export default 	{
	color: '#65B0E6',
	hero: {
		background: 'hero/background.jpg',
		logo: 'hero/logo.png',
	},
	overview: {
		image: 'overview/zlem-box.png',
		title: 'zlem_title',
		description: 'zlem_overview_description',
		iconCols: 3,
		icons: [
			{
				text: 'zlem_overview_icon_1',
				file: 'overview/icons/morning-icon.png',
			},
			{
				text: 'zlem_overview_icon_2',
				file: 'overview/icons/slim-icon.png',
			},
			{
				text: 'zlem_overview_icon_3',
				file: 'overview/icons/mood-icon.png',
			},
			{
				text: 'zlem_overview_icon_4',
				file: 'overview/icons/arm-icon.png',
			},
			{
				text: 'zlem_overview_icon_5',
				file: 'overview/icons/sleep-icon.png',
			},
			{
				text: 'zlem_overview_icon_6',
				file: 'overview/icons/energy-icon.png',
			},
		],
	},
	video: {
		title: 'zlem_video_title',
		url: {
			en: 'https://player.vimeo.com/video/493519065',
			es: 'https://player.vimeo.com/video/495260027',
			ja: 'https://player.vimeo.com/video/493519065',
		},
		previewImg: 'video/preview.png',
	},
	benefits: {
		sections: [
			{
				reversed: false,
				image: 'benefits/benefits-1.jpg',
				title: 'zlem_benefits_title',
				content: 'zlem_benefits_left_content',
			},
			{
				reversed: true,
				image: 'benefits/benefits-2.jpg',
				title: 'zlem_benefits_title',
				content: 'zlem_benefits_right_content',
				cta: {
					url: PRODUCTS_LINKS.ZL,
					text: 'download_brochure',
					target: '_blank',
				},
			},
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727113460?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/830597919?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727113460?h=fbc2f68c3c&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/shanna-b.png',
				author: 'Shanna B., USA',
				content: 'zlem_review_1',
				stars: 5,
			},
			{
				image: 'reviews/emily-c.png',
				author: 'Emily C., USA',
				content: 'zlem_review_2',
				stars: 5,
			},
			{
				image: 'reviews/thressa-s.png',
				author: 'Thressa S., USA',
				content: 'zlem_review_3',
				stars: 5,
			},
			{
				image: 'reviews/brett-h.png',
				author: 'Brett H., Florida',
				content: 'zlem_review_4',
				stars: 5,
			},
			{
				image: 'reviews/rory-r.png',
				author: 'Rory R., Utah',
				content: 'zlem_review_5',
				stars: 5,
			},
		],
	},
	buy: {
		background: 'buynow/buy-now.jpg',
		title: 'zlem_title',
		searchName: 'Zlem',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.jpg',
		cta: {
			text: 'contact_title',
		},
	},
};
