export default 	{
	color: '#DF703D',
	hero: {
		background: 'hero/background.jpg',
		logo: 'hero/logo.png',
		slogan: 'an_epic_journey',
	},
	video: {
		title: 'opportunity_2_video_title',
		url: {
			en: 'https://player.vimeo.com/video/589378244',
			es: 'https://player.vimeo.com/video/910434715',
			ja: 'https://player.vimeo.com/video/589378244',
		},
		previewImg: 'video/preview.png',
	},
	about: {
		sections: [
			{
				reversed: true,
				image: 'about/about.jpg',
				content: 'opportunity_2_about_content',
				cta: {
					url: 'https://velovita.com/',
					text: 'learn_more',
					target: '_blank',
				},
			},
		],
	},
	contact: {
		reversed: false,
		title: 'opportunity_contact_title',
		content: 'opportunity_contact_content',
		image: 'contact/lets-connect.png',
		cta: {
			text: 'opportunity_contact_cta',
		},
	},
};
