import { PRODUCTS_LINKS } from '@/settings/Products';

const colors = {
	primary: '#df6a2e',
	secondary: '#3f3f3f',
	tertiary: '#fcbe5e',
};

const productColors = {
	bran: '#df713d',
	zlem: '#66b2e8',
	byom: '#794c99',
};

export default 	{
	colors,
	hero: {
		background: 'hero/background.png',
		logo: 'hero/logo.png',
		image: 'hero/lite-logo.svg',
		content: [
			{
				text: 'lite_hero_description',
				highlight: true,
				class: {
					default: {
						big: 'my-4 pr-5',
						small: 'my-4 text-center',
					},
				},
				style: {
					big: 'line-height: 1.8;',
					small: 'line-height: 1.8;',
				},
			},
		],
		ctas: [
			{
				class: 'text-white',
				scrollTo: 'buy-section',
				text: 'buy_now',
				color: colors.primary,
				type: 'filled',
			},
		],
	},
	summary: {
		background: 'background: linear-gradient(to right, rgb(251 189 94), rgb(223 113 62))',
		title: 'lite_summary_title',
		description: 'lite_summary_description',
		iconCols: 5,
		brochureUrl: PRODUCTS_LINKS.LT,
		icons: [
			{
				text: 'lite_summary_icon_1',
				file: 'summary/icons/checkmark.svg',
			},
			{
				text: 'lite_summary_icon_2',
				file: 'summary/icons/checkmark.svg',
			},
			{
				text: 'lite_summary_icon_3',
				file: 'summary/icons/checkmark.svg',
			},
		],
	},
	overview: {
		tabNames: [
			{
				title: 'lite_byom',
				color: productColors.byom,
				gradient: {
					from: '#e38bfd',
					to: '#794d99',
				},
			},
			{
				title: 'lite_zlem',
				color: productColors.zlem,
				gradient: {
					from: '#65b1e7',
					to: '#012f75',
				},
			},
			{
				title: 'lite_bran',
				color: productColors.bran,
				gradient: {
					from: '#fbbd5d',
					to: '#df713e',
				},
			},
		],
		slides: [
			{
				title: 'lite_overview_bran_title',
				description: 'lite_overview_bran_description',
				bottomText: 'lite_overview_bran_bottom_text',
				banner: 'overview/bran/product-banner.png',
				image: 'overview/bran/bran-lite-bottle.png',
				highlightColor: productColors.bran,
				icons: [
					{
						text: 'lite_overview_bran_icon_1',
						file: 'overview/bran/bran-benefits-01.svg',
					},
					{
						text: 'lite_overview_bran_icon_2',
						file: 'overview/bran/bran-benefits-02.svg',
					},
					{
						text: 'lite_overview_bran_icon_3',
						file: 'overview/bran/bran-benefits-03.svg',
					},
					{
						text: 'lite_overview_bran_icon_4',
						file: 'overview/bran/bran-benefits-04.svg',
					},
					{
						text: 'lite_overview_bran_icon_5',
						file: 'overview/bran/bran-benefits-05.svg',
					},
				],
				ctas: [
					{
						text: 'lite_watch_video',
						videoUrl: {
							en: 'https://player.vimeo.com/video/442442740',
							es: 'https://player.vimeo.com/video/472618597',
							ja: 'https://player.vimeo.com/video/577316793',
						},
					},
					{
						text: 'lite_supplement_facts',
					},
				],
				supplementFacts: {
					image: 'overview/bran/bran-supp-facts.png',
					content: 'lite_bran_supplement_facts',
					badges: [
						{
							image: 'overview/bran/product_badges_non_gmo.svg',
							text: 'lite_non_gmo',
						},
						{
							image: 'overview/bran/product_badges_gluten_free.svg',
							text: 'lite_gluten_free',
						},
						{
							image: 'overview/bran/product_badges_vegan.svg',
							text: 'lite_vegan',
						},
						{
							image: 'overview/bran/product_badges_keto_friendly.svg',
							text: 'lite_keto_friendly',
						},
						{
							image: 'overview/bran/product_badges_dairy_free.svg',
							text: 'lite_dairy_free',
						},
					],
				},
			},
			{
				title: 'lite_overview_zlem_title',
				description: 'lite_overview_zlem_description',
				bottomText: 'lite_overview_zlem_bottom_text',
				banner: 'overview/zlem/product-banner.png',
				image: 'overview/zlem/zlem-lite-bottle.png',
				highlightColor: productColors.zlem,
				icons: [
					{
						text: 'lite_overview_zlem_icon_1',
						file: 'overview/zlem/zlem-benefits-01.svg',
					},
					{
						text: 'lite_overview_zlem_icon_2',
						file: 'overview/zlem/zlem-benefits-02.svg',
					},
					{
						text: 'lite_overview_zlem_icon_3',
						file: 'overview/zlem/zlem-benefits-03.svg',
					},
					{
						text: 'lite_overview_zlem_icon_4',
						file: 'overview/zlem/zlem-benefits-04.svg',
					},
					{
						text: 'lite_overview_zlem_icon_5',
						file: 'overview/zlem/zlem-benefits-05.svg',
					},
					{
						text: 'lite_overview_zlem_icon_6',
						file: 'overview/zlem/zlem-benefits-06.svg',
					},
				],
				ctas: [
					{
						text: 'lite_watch_video',
						videoUrl: {
							en: 'https://player.vimeo.com/video/493519065',
							es: 'https://player.vimeo.com/video/495260027',
							ja: 'https://player.vimeo.com/video/493519065',
						},
					},
					{
						text: 'lite_supplement_facts',
					},
				],
				supplementFacts: {
					image: 'overview/zlem/zlem-supp-facts.png',
					content: 'lite_zlem_supplement_facts',
					badges: [
						{
							image: 'overview/zlem/product_badges_non_gmo.svg',
							text: 'lite_non_gmo',
						},
						{
							image: 'overview/zlem/product_badges_gluten_free.svg',
							text: 'lite_gluten_free',
						},
						{
							image: 'overview/zlem/product_badges_vegan.svg',
							text: 'lite_vegan',
						},
						{
							image: 'overview/zlem/product_badges_keto_friendly.svg',
							text: 'lite_keto_friendly',
						},
						{
							image: 'overview/zlem/product_badges_dairy_free.svg',
							text: 'lite_dairy_free',
						},
					],
				},
			},
			{
				title: 'lite_overview_byom_title',
				description: 'lite_overview_byom_description',
				bottomText: 'lite_overview_byom_bottom_text',
				banner: 'overview/byom/product-banner.png',
				image: 'overview/byom/byom-lite-bottle.png',
				highlightColor: productColors.byom,
				icons: [
					{
						text: 'lite_overview_byom_icon_1',
						file: 'overview/byom/byom-benefits-01.svg',
					},
					{
						text: 'lite_overview_byom_icon_2',
						file: 'overview/byom/byom-benefits-02.svg',
					},
					{
						text: 'lite_overview_byom_icon_3',
						file: 'overview/byom/byom-benefits-03.svg',
					},
					{
						text: 'lite_overview_byom_icon_4',
						file: 'overview/byom/byom-benefits-04.svg',
					},
					{
						text: 'lite_overview_byom_icon_5',
						file: 'overview/byom/byom-benefits-05.svg',
					},
				],
				ctas: [
					{
						text: 'lite_watch_video',
						videoUrl: {
							en: 'https://player.vimeo.com/video/800592563',
							es: 'https://player.vimeo.com/video/801766143',
							ja: 'https://player.vimeo.com/video/800592563',
						},
					},
					{
						text: 'lite_supplement_facts',
					},
				],
				supplementFacts: {
					image: 'overview/byom/byom-supp-facts.png',
					content: 'lite_byom_supplement_facts',
					badges: [
						{
							image: 'overview/byom/product_badges_non_gmo.svg',
							text: 'lite_non_gmo',
						},
						{
							image: 'overview/byom/product_badges_gluten_free.svg',
							text: 'lite_gluten_free',
						},
						{
							image: 'overview/byom/product_badges_vegan.svg',
							text: 'lite_vegan',
						},
						{
							image: 'overview/byom/product_badges_keto_friendly.svg',
							text: 'lite_keto_friendly',
						},
						{
							image: 'overview/byom/product_badges_dairy_free.svg',
							text: 'lite_dairy_free',
						},
					],
				},
			},
		],
		background: 'overview/velovita-pattern.png',
	},
	buy: {
		background: 'linear-gradient(to right, rgb(251 189 94), rgb(223 113 62))',
		title: 'lite_title',
		searchName: 'Lite',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.png',
		background: 'linear-gradient(to right, rgb(251 189 94), rgb(223 113 62))',
		cta: { text: 'contact_title' },
	},
	disclaimer: {
		text: 'lite_disclaimer',
	},
};
