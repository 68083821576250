import { PRODUCTS_LINKS } from '@/settings/Products';

export default 	{
	colors: {
		primary: '#e73340',
		secondary: '#a12b2a',
	},
	introduction: {
		title: 'plos_title',
		description: 'plos_introduction',
		searchName: 'Plos',
		videoUrl: {
			en: 'https://player.vimeo.com/video/660749047?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
			es: 'https://player.vimeo.com/video/678421014?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/660749047?h=473a8d8ea6&title=0&byline=0&portrait=0&speed=0&badge=0&autopause=0&autoplay=1',
		},
		galleryImages: [
			'introduction/plos-mocha-snap-1.png',
			'introduction/plos-mocha-snap-2.png',
			'introduction/plos-snap-3.png',
			'introduction/plos-snap-4.png',
		],
		variantsImages: ['introduction/plos-mocha.png'],
	},
	summary: {
		title: 'plos_summary_title',
		description: 'plos_summary_description',
		iconCols: 6,
		image: 'summary/plos-summary.png',
		brochureUrl: PRODUCTS_LINKS.PL,
		icons: [
			{
				text: 'plos_summary_icon_1',
				file: 'summary/icons/healthy-weight-icon.png',
			},
			{
				text: 'plos_summary_icon_2',
				file: 'summary/icons/appetite-icon.png',
			},
			{
				text: 'plos_summary_icon_3',
				file: 'summary/icons/healthy-metabolism-icon.png',
			},
			{
				text: 'plos_summary_icon_4',
				file: 'summary/icons/energy-icon.png',
			},
			{
				text: 'plos_summary_icon_5',
				file: 'summary/icons/lipid-synthesis-icon.png',
			},
			{
				text: 'plos_summary_icon_6',
				file: 'summary/icons/metabolism-icon.png',
			},
		],
	},
	overview: {
		sections: [
			{
				reversed: true,
				header: 'plos_benefits_1_header',
				content: { top: 'plos_benefits_1_content_1' },
				image: 'overview/overview-1.png',
			},
			{
				reversed: false,
				header: 'plos_benefits_2_header',
				content: {
					top: 'plos_benefits_2_content_1',
					bottom: 'plos_benefits_2_content_2',
				},
				image: 'overview/overview-2.png',
			},
			{
				reversed: true,
				header: 'plos_benefits_3_header',
				content: {
					top: 'plos_benefits_3_content_1',
					bottom: 'plos_benefits_3_content_2',
				},
				image: 'overview/overview-3.png',
			},
		],
	},
	tabs: {
		benefits: {
			content: 'plos_tabs_benefits_content',
			image: 'introduction/plos-snap-4.png',
		},
		ingredients: { content: 'plos_tabs_ingredients_content' },
		supplementsFacts: {
			images: {
				supplementsFacts: 'supplement/mocha-facts.png',
				labels: 'supplement/labels.png',
			},
			content: 'plos_tabs_supplements_facts_content',
		},
		faqs: [
			{ q: 'plos_tabs_faqs_fda_ask', a: 'plos_tabs_faqs_fda_answer' },
			{ q: 'plos_tabs_faqs_nsf_ask', a: 'plos_tabs_faqs_nsf_answer' },
			{ q: 'plos_tabs_faqs_kosher_ask', a: 'plos_tabs_faqs_kosher_answer' },
			{ q: 'plos_tabs_faqs_keto_ask', a: 'plos_tabs_faqs_keto_answer' },
			{ q: 'plos_tabs_faqs_sweeten_ask', a: 'plos_tabs_faqs_sweeten_answer' },
		],
	},
	reviews: {
		videoUrl: {
			en: 'https://player.vimeo.com/video/727154135?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			es: 'https://player.vimeo.com/video/840622649?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
			ja: 'https://player.vimeo.com/video/727154135?h=2dc6805dfd&color=DF703E&title=0&byline=0&portrait=0&autoplay=1',
		},
		textColor: '#7C7C7C',
		reviews: [
			{
				image: 'reviews/ronald-t.png',
				author: 'Ronald T., USA',
				content: 'plos_review_1',
				stars: 5,
			},
			{
				image: 'reviews/callie-a.png',
				author: 'Callie A., USA',
				content: 'plos_review_2',
				stars: 5,
			},
			{
				image: 'reviews/richard-a.png',
				author: 'Richard A., USA',
				content: 'plos_review_3',
				stars: 5,
			},
			{
				image: 'reviews/dami-p.png',
				author: 'Dami P., USA',
				content: 'plos_review_4',
				stars: 5,
			},
		],
	},
	buy: {
		background: 'buynow/buy-now.jpg',
		title: 'plos_title_2',
		searchName: 'Plos',
	},
	contact: {
		reversed: false,
		title: 'contact_title',
		content: 'contact_content',
		image: 'contact/contact-me.jpg',
		cta: { text: 'contact_title' },
	},
};
