export default 	{
	color: '#DF703D',
	hero: {
		background: 'hero/background.jpg',
		logo: 'hero/logo.png',
		slogan: 'its_about_freedom',
	},
	video: {
		title: 'opportunity_1_video_title',
		url: {
			en: 'https://player.vimeo.com/video/455872537',
			es: 'https://player.vimeo.com/video/834859911',
			ja: 'https://player.vimeo.com/video/455872537',
		},
		previewImg: 'video/preview.png',
	},
	biohacking: {
		title: 'opportunity_1_products_title',
		image: 'biohacking/biohacking.jpg',
		content: 'opportunity_1_products_content',
	},
	catalog: {
		availability: {
			default: ['BR', 'ZL', 'UT', 'PL'],
		},
		products: [
			{
				sku: 'BR',
				name: 'opportunity_1_snaps_bran_title',
				description: 'opportunity_1_snaps_bran_content',
				image: 'catalog/bran-drop.png',
				url: {
					en: 'https://player.vimeo.com/video/442442740',
					es: 'https://player.vimeo.com/video/472618597',
					ja: 'https://player.vimeo.com/video/577316793',
				},
			},
			{
				sku: 'ZL',
				name: 'opportunity_1_snaps_zlem_title',
				description: 'opportunity_1_snaps_zlem_content',
				image: 'catalog/zlem-drop.png',
				url: {
					en: 'https://player.vimeo.com/video/493519065',
					es: 'https://player.vimeo.com/video/495260027',
					ja: 'https://player.vimeo.com/video/493519065',
				},
			},
			{
				sku: 'UT',
				name: 'opportunity_1_snaps_uuth_title',
				description: 'opportunity_1_snaps_uuth_content',
				image: 'catalog/uuth-drop.png',
				url: {
					en: 'https://player.vimeo.com/video/582227359',
					es: 'https://player.vimeo.com/video/659791970',
					ja: 'https://player.vimeo.com/video/582227359',
				},
			},
			{
				sku: 'PL',
				name: 'opportunity_1_snaps_plos_title',
				description: 'opportunity_1_snaps_plos_content',
				image: 'catalog/plos-drop.png',
				url: {
					en: 'https://player.vimeo.com/video/660749047?h=473a8d8ea6',
					es: 'https://player.vimeo.com/video/678421014?h=473a8d8ea6',
					ja: 'https://player.vimeo.com/video/660749047?h=473a8d8ea6',
				},
			},
		],
	},
	about: {
		sections: [
			{
				reversed: true,
				image: 'about/about-1.jpg',
				content: 'opportunity_1_about_right_content',
			},
			{
				reversed: false,
				image: 'about/about-2.jpg',
				content: 'opportunity_1_about_left_content',
			},
		],
	},
	contact: {
		reversed: false,
		title: 'opportunity_contact_title',
		content: 'opportunity_contact_content',
		image: 'contact/lets-connect.jpg',
		cta: {
			text: 'opportunity_contact_cta',
		},
	},
};
