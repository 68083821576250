import bran from './Bran';
import zlem from './Zlem';
import uuth from './Uuth';
import plos from './Plos';
import byom from './Byom';
import lite from './Lite';
import opportunity1 from './Opportunity1';
import opportunity2 from './Opportunity2';

export const PRODUCT_PAGES = {
	bran,
	zlem,
	uuth,
	plos,
	byom,
	lite,
};
export const OPPORTUNITIES_PAGES = {
	'opportunity-1': opportunity1,
	'opportunity-2': opportunity2,
};

export default {};
