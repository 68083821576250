<template>
	<div :style="`min-height:${fixedMinHeight ? fixedMinHeight : minHeight}px`">
		<slot v-if="shouldRender" />
	</div>
</template>

<script>

// This component is based on this article: https://medium.com/js-dojo/lazy-rendering-in-vue-to-improve-performance-dcccd445d5f
export default {
	props: {
		renderOnIdle: {
			type: Boolean,
			default: false,
		},
		unmount: {
			type: Boolean,
			default: false,
		},
		minHeight: {
			type: [Number, String],
			default: 0,
		},
		unmountDelay: {
			type: Number,
			default: 10000,
		},
	},
	data() {
		return {
			shouldRender: false,
			fixedMinHeight: 0,
			unmountTimer: undefined,
			renderTimer: undefined,
		};
	},
	beforeUnmount() {
		clearTimeout(this.unmountTimer);
		clearTimeout(this.renderTimer);
	},
	mounted() {
		const observer = new IntersectionObserver(
			([{ isIntersecting, target }]) => {
				if (isIntersecting) {
					// Perhaps the user re-scrolled to a component that was set to unmount. In that case stop the unmounting timer
					clearTimeout(this.unmountTimer);
					// If we're dealing re-rendering lets add a waiting period of 200ms before rendering. If a component enters the viewport and also leaves it within 200ms it will not render at all. This saves work and improves performance when user scrolls very fast
					this.renderTimer = setTimeout(
						() => {
							this.shouldRender = true;
						},
						this.unmount ? 200 : 0,
					);
					this.shouldRender = true;
					if (!this.unmount) {
						observer.unobserve(target);
					}
				} else if (this.unmount) {
					// If the component was set to render, cancel that
					clearTimeout(this.renderTimer);
					this.unmountTimer = setTimeout(() => {
						this.fixedMinHeight = target.clientHeight;
						this.shouldRender = false;
					}, this.unmountDelay);
				}
			},
			{
				root: null,
				threshold: 0,
				rootMargin: '300px 0px 300px 0px',
			},
		);

		if (this.renderOnIdle) {
			this.onIdle(() => {
				this.shouldRender = true;
				if (!this.unmount) {
					observer.unobserve(this.$el);
				}
			});
		}

		observer.observe(this.$el);
	},
	methods: {
		onIdle(cb = () => {}) {
			if ('requestIdleCallback' in window) {
				window.requestIdleCallback(cb);
			} else {
				setTimeout(() => {
					this.$nextTick(cb);
				}, 300);
			}
		},
	},
};
</script>
